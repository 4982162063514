<template>
<div v-if="introBanner" style="overflow:hidden;height:570px;width:360px;max-height: 570px;max-width:360px;background: #fff;position:relative;border-radius:5px;display: flex;justify-content: center;align-items: center;">
  <div class="intro_preview" v-if="intro_type_0_preview" style="position:absolute;top:0;left:0;height:570px;width:360px;">

  </div>
  <v-btn
      style="
                position: absolute;
                z-index:200;
                top: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                left: 5px;
                background-color: rgba(255, 255, 255, 0.4);
              "
      small
      depressed
  >Skip {{ currentTime }} s</v-btn
  >
  <v-icon style="position:absolute;cursor: pointer;z-index: 251;" :style="`right:5px;top:5px;`" :color="introBanner.styles.icon_color" :size="introBanner.styles.icon_size">{{introBanner.styles.icon}}</v-icon>

  <img v-if="introBanner.type === 0" :src="introBanner.image[landing.current_lang] ? introBanner.image[landing.current_lang] : require('@/assets/none.png')" :style="`height:100%;width:100%;`" alt="">
  <video
      v-if="introBanner.type === 1 && introBanner.video[landing.current_lang]"
      :src="introBanner.video[landing.current_lang]"
      playsinline
      autoplay
      muted
      loop
      height="570px"
      width="360px"
      style="
                      position: absolute;
                      object-fit: cover;
                      z-index: 250;
                    "
  ></video>
  <div v-if="introBanner.type === 2">
    <div v-if="intro_upload_pro_top_img" class="intro_top_img_preview" style="overflow:hidden;width:360px;height:200px;position:absolute;top:0;left:0;">

    </div>
    <div v-else style="width:360px;height:200px;position:absolute;top:0;left:0;">
      <img :src="introBanner.pro[landing.current_lang].top_img?introBanner.pro[landing.current_lang].top_img:require('@/assets/none.png')" alt="">
    </div>
    <div v-if="intro_upload_pro_logo_img" class="intro_logo_img_preview" :style="`overflow:hidden;width:${introBanner.pro.logo_width}px;height:${introBanner.pro.logo_height}px;position:absolute;top:150px;left:50%;margin-left:-${+introBanner.pro.logo_width/2}px;`"></div>
    <div v-else :style="`width:${introBanner.pro.logo_width}px;height:${introBanner.pro.logo_height}px;position:absolute;top:150px;left:50%;margin-left:-${+introBanner.pro.logo_width/2}px;`">
      <img :src="introBanner.pro[landing.current_lang].logo_img?introBanner.pro[landing.current_lang].logo_img:require('@/assets/none.png')" style="height:100%;width:100%;" alt="">
    </div>
    <div
        :style="`position:absolute;left:0;top:${+introBanner.pro.logo_height+175}px;width:100%;`"
        v-html="htmlData(introBanner.pro[landing.current_lang].main_description)"
    ></div>
    <div style="position:absolute;bottom:0;width:100%;">
      <v-icon>{{introBanner.pro[landing.current_lang].btn_next}}</v-icon>
      <div v-html="htmlData(introBanner.pro[landing.current_lang].text_next)">
      </div>
    </div>
</div>
</div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "IntroPreview",
  computed:{
    ...mapState('intro',['introBanner','intro_upload_pro_logo_img','intro_upload_pro_top_img','intro_type_0_preview']),
    ...mapState(['landing']),
  },
  data(){
    return{
      currentTime:5,
      timer: null,
    }
  },
  watch:{
    currentTime(time) {
      if (time === 0) {
        this.currentTime = this.introBanner.show_time;
      }
    },
  },
  methods:{
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime--;
      }, 1000);
    },
    htmlData(data, type) {
      if (data) {
        const weatherInd = data.split(" ").indexOf(":weather:");
        let result = data.split(" ");
        if (weatherInd && this.landing.advanced.global.show_weather) {
          const newArr = result;
          newArr[weatherInd] =
              this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
                  0
              ) + " ℃";
          result = newArr;
        }
        const timeInd = data.split(" ").indexOf(":time:");
        if (timeInd) {
          const newArr = result;
          newArr[timeInd] = this.datenow;
          result = newArr;
        }
        const dateInd = data.split(" ").indexOf(":date:");
        if (dateInd) {
          const newArr = result;
          newArr[dateInd] = this.dateToday;
          result = newArr;
        }
        const user_name = data.split(" ").indexOf(":name:");
        if (
            user_name &&
            this.landing.advanced.global.auth &&
            this.landing.advanced.auth.name &&
            type === "title"
        ) {
          const newArr = result;
          newArr[user_name] = "Agent";
          result = newArr;
        }
        const user_surname = data.split(" ").indexOf(":surname:");
        if (
            user_surname &&
            this.landing.advanced.global.auth &&
            this.landing.advanced.auth.name &&
            type === "title"
        ) {
          const newArr = result;
          newArr[user_surname] = "Smith";
          result = newArr;
        }
        const user_birthday = data.split(" ").indexOf(":birthday:");
        if (
            user_surname &&
            this.landing.advanced.global.auth &&
            this.landing.advanced.auth.name &&
            type === "title"
        ) {
          const newArr = result;
          newArr[user_birthday] = "24.03.1999";
          result = newArr;
        }
        const user_room = data.split(" ").indexOf(":room:");
        if (
            user_surname &&
            this.landing.advanced.global.auth &&
            this.landing.advanced.auth.name &&
            type === "title"
        ) {
          const newArr = result;
          newArr[user_room] = "707";
          result = newArr;
        }

        const user_auth = data.split(" ").indexOf(":auth:");
        if (user_auth && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_auth] = `<a href="#">Login</a>`;
          result = newArr;
        }
        const user_logout = data.split(" ").indexOf(":logout:");
        if (user_logout && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_logout] = `<a href="#">Logout</a>`;
          result = newArr;
        }
        return result.join(" ");
      }
    },
  },
  mounted(){
    console.log(this.introBanner);
    if(this.introBanner){
      this.startTimer();
    }
  }
}
</script>

<style scoped lang="scss">

</style>